import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react'
import { nanoid } from '../utils/id'
import React, { FC } from 'react'

export type EventTrackingName =
  | 'sales_training_program__pageview'
  | 'sales_training__pageview'
  | 'sales_analytics__pageview'
  | 'product_specific__pageview'
  | 'product_overview__pageview'
  | 'job_specific__pageview'
  | 'sales_training_programs__click'
  | 'navbar_link_category__click'
  | 'navbar_link_item__click'
  | 'related_link_sales_enablement__click'
  | 'contact_form__submitted'
  | 'contact_form_multistep__submitted'
  | 'download_resource_form__submitted'
  | 'download_resource_form_email_only__submitted'

const utmSource = () => {
  if (typeof window === 'undefined') {
    return null
  }
  const thisURL = new URL(window.location.href)
  return thisURL.searchParams.get('utm-source')
}

const storedSource = (() => {
  if (typeof window === 'undefined') {
    return null
  }
  const src = utmSource() || window.localStorage?.getItem('source') || null
  if (src) {
    window.localStorage?.setItem('source', src)
  }
  return src
})()

const userKey = (() => {
  if (typeof window !== 'undefined') {
    if (window.localStorage.getItem('splitkey')) {
      return window.localStorage?.getItem('splitkey')
    }

    const newKey = nanoid()
    window.localStorage.setItem('splitkey', newKey)

    return window.localStorage.getItem('splitkey') || undefined
  }

  return undefined
})()

export const splitFactory = SplitSdk({
  core: {
    authorizationKey: process.env.GATSBY_SPLIT_IO_AUTH_KEY || '',
    key: userKey || '',
  },
  startup: {
    readyTimeout: 1.5, // seconds
  },
  debug: process.env.NODE_ENV === 'development' && false,
})

export const useSplitTracking = () => {
  const client = splitFactory.client()

  return (
    event: EventTrackingName,
    value: number | undefined,
    properties: any = null,
  ) => {
    let q = client.track('user', event, value, properties)
    console.log({ event, client, q, properties })
  }
}

export const SplitFactoryProvider: FC = ({ children }) => {
  const attrs = {
    source: storedSource,
  }

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <SplitFactory attributes={attrs} factory={splitFactory}>
      <>{children}</>
    </SplitFactory>
  )
}
